import GoogleMapReact from "google-map-react"
import React from "react"
import styles from "./google-map.module.scss"

const defaultProps = {
  center: {
    lat: 53.8143,
    lng: -1.54448,
  },
  zoom: 15,
}

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = () => (
  <div className={styles.googleMap}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyD2Dyb8x1OXrtAFMFfLhLGxAtBqE412rnE" }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <AnyReactComponent
        lat={59.955413}
        lng={30.337844}
        text={"Kreyser Avrora"}
      />
    </GoogleMapReact>
  </div>
)

export default GoogleMap
