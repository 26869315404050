import React from "react"
import styles from "./contact-form.module.scss"

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      heroPassword: "",
      name: "",
      email: "",
      subject: "",
      message: "",
      emailSent: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  async handleSubmit(event) {
    event.preventDefault()

    const fetchResponse = await fetch(
      "https://taqoxb0ln3.execute-api.eu-west-1.amazonaws.com/dev/contact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state),
      }
    )

    if (fetchResponse && fetchResponse.status === 200) {
      this.setState({
        emailSent: true,
      })
    }
  }

  isValidEmail(field) {
    // eslint-disable-next-line
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      field
    )
  }

  isNotEmpty(field) {
    return field !== ""
  }

  formIsValid() {
    return (
      this.isNotEmpty(this.state.name) &&
      this.isValidEmail(this.state.email) &&
      this.isNotEmpty(this.state.subject) &&
      this.isNotEmpty(this.state.message)
    )
  }

  render() {
    return (
      <div className={`${styles.contactForm}`}>
        <div className={this.state.emailSent ? styles.emailSent : ""}>
          <h2>Get in touch</h2>
          <form onSubmit={this.handleSubmit}>
            <label
              style={{ display: "none" }}
              className={
                this.state.heroPassword ? styles.valid : styles.invalid
              }
            >
              <input
                tabIndex="-1"
                type="text"
                name="heroPassword"
                value={this.state.heroPassword}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </label>
            <label>
              Your name{" "}
              <span
                className={
                  this.isNotEmpty(this.state.name)
                    ? styles.valid
                    : styles.invalid
                }
              >
                (required){this.state.nameValid}
              </span>
              <input
                tabIndex="1"
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
            </label>
            <label>
              Your email{" "}
              <span
                className={
                  this.isValidEmail(this.state.email)
                    ? styles.valid
                    : styles.invalid
                }
              >
                (required)
              </span>
              <input
                tabIndex="2"
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
            </label>
            <label>
              Subject{" "}
              <span
                className={
                  this.isNotEmpty(this.state.subject)
                    ? styles.valid
                    : styles.invalid
                }
              >
                (required)
              </span>
              <input
                tabIndex="3"
                type="text"
                name="subject"
                value={this.state.subject}
                onChange={this.handleInputChange}
              />
            </label>
            <label>
              Your message{" "}
              <span
                className={
                  this.isNotEmpty(this.state.message)
                    ? styles.valid
                    : styles.invalid
                }
              >
                (required)
              </span>
              <textarea
                tabIndex="4"
                type="text"
                name="message"
                value={this.state.message}
                onChange={this.handleInputChange}
              ></textarea>
            </label>
            <button
              type="submit"
              disabled={this.formIsValid() ? styles.valid : styles.invalid}
            >
              Send
            </button>
          </form>
        </div>
        <div className={!this.state.emailSent ? styles.emailSent : ""}>
          <h2>Thanks for your email!</h2>
          <p>We will be in touch as soon as possible.</p>
        </div>
      </div>
    )
  }
}
